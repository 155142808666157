import { CircularProgress } from "@material-ui/core";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import MainLayout from "@/components/layouts/MainLayout/MainLayout";

import useCurrentUser from "../hooks/useCurrentUser";

const HomePage = () => {
  const router = useRouter();
  const user = useCurrentUser();

  useEffect(() => {
    if (router.isReady) {
      if (user.isLoggedIn) {
        router.push("/requests");
      } else if (!user.isLoading && !user.isLoggedIn) {
        router.push("/sign-in");
      }
    }
  }, [router.isReady, user.isLoggedIn, user.isLoading]);

  return (
    <MainLayout>
      <CircularProgress />
    </MainLayout>
  );
};

export default HomePage;
